import {
  KikCustomerFlags as CustomerFlags,
  kikCustomerFlagsSchema as customerFlagsSchema,
  KikGenericResponse,
} from "@kikocosmeticsorg/uc-api-nest-common-fe";
import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { useQuery } from "@tanstack/react-query";

import { useAuthContext } from "~/contexts/auth";
import { HttpService } from "~/services/http";
import Logger from "~/services/logger/logger";
import { ApiConstants } from "~/shared/api/api-constants.class";
import loyaltyUtils from "~/utils/loyalty-utils";

const useSubscriptions = () => {
  const { isAuthenticated, user } = useAuthContext();
  const evaEndpointUrl = hooks.useGetState(state.core.evaEndpointUrlState);
  const evaOrganizationUnitId = hooks.useGetState(state.core.requestedOrganizationUnitIdState);

  // Private

  const _isUserSubscriptionActive = (s?: EVA.Core.GetUserSubscriptionsResponse.UserSubscriptionDto) => s?.Status === 2;

  // Public

  const evaServiceGetUserSubscriptions = hooks.useCallService({
    service: Core.GetUserSubscriptions,
  });

  const evaServiceSubscribeUser = hooks.useCallService({
    service: Core.SubscribeUser,
  });

  const evaServiceUnsubscribeUser = hooks.useCallService({
    service: Core.UnsubscribeUser,
  });

  // Used to get the list of all subscriptions
  const { data: availableSubscriptions } = useQuery({
    queryKey: ["evaServiceGetAllSubscriptions"],
    staleTime: Infinity,
    queryFn: () => {
      return HttpService.$http<KikGenericResponse<EVA.Core.GetAvailableSubscriptionsResponse.SubscriptionDto[]>>({
        url: ApiConstants.endpointsV2.authSubscriptions,
        queryParams: {
          organizationUnitId: evaOrganizationUnitId!,
        },
      }).then((r) => r.data);
    },
    enabled: !!evaOrganizationUnitId,
  });

  // Used to get the list of all subscriptions
  const {
    data: userSubscriptions,
    refetch: refetchUserSubscriptions,
    isLoading: isLoadingUserSubscriptions,
  } = useQuery({
    queryKey: ["evaServiceGetUserSubscriptions", user?.ID],
    staleTime: Infinity,
    queryFn: async () => {
      const resp = await evaServiceGetUserSubscriptions();

      return resp?.Subscriptions;
    },
    enabled: !!(evaEndpointUrl && isAuthenticated),
  });

  const getUserSubscriptionByBackendId = (BackendID: keyof CustomerFlags) => {
    return userSubscriptions?.find((s) => s.Subscription?.BackendID === BackendID);
  };

  const getUserSubscriptionValueByBackendId = (BackendID: keyof CustomerFlags): boolean | void => {
    return _isUserSubscriptionActive(getUserSubscriptionByBackendId(BackendID));
  };

  const pushSubscriptionsByFlags = async (flags: Partial<CustomerFlags>) => {
    const subs =
      availableSubscriptions?.filter((s) => {
        return !!flags[s.BackendID as keyof CustomerFlags];
      }) || [];
    Logger.instance.debug("pushSubscriptionsByFlags called", {
      flags,
      filteredSubs: subs,
    });

    return subs.length
      ? evaServiceSubscribeUser({
          UserID: user?.ID,
          Subscriptions: subs.map((s) => {
            return {
              SubscriptionID: s.ID,
            };
          }),
        }).finally(() => refetchUserSubscriptions())
      : [];
  };

  const removeSubscriptionsByFlags = async (flags: Partial<CustomerFlags>): Promise<CustomerFlags> => {
    const subs =
      userSubscriptions?.filter((s) => {
        return (
          _isUserSubscriptionActive(s) &&
          flags.hasOwnProperty(s.Subscription?.BackendID as keyof CustomerFlags) &&
          !flags[s.Subscription?.BackendID as keyof CustomerFlags]
        );
      }) || [];
    Logger.instance.debug("removeSubscriptionsByFlags called", {
      flags,
      filteredSubs: subs,
    });
    const calls = subs.map((s) => {
      return evaServiceUnsubscribeUser({
        UserID: user?.ID,
        SubscriptionID: s.Subscription?.ID!,
      });
    });

    return Promise.all(calls)
      .finally(() => refetchUserSubscriptions())
      .then((results) => {
        return subs.reduce((acc, sub, index) => {
          acc[sub.Subscription?.BackendID as keyof CustomerFlags] = results[index]?.Status === 3;

          return acc;
        }, customerFlagsSchema.parse({}));
      });
  };

  const userSubscriptionsToFlags = (): Record<keyof CustomerFlags, boolean | void> => {
    return {
      LoyaltyProgram: getUserSubscriptionValueByBackendId("LoyaltyProgram"),
      MarketingOptIn: getUserSubscriptionValueByBackendId("MarketingOptIn"),
      ProfilingOptIn: getUserSubscriptionValueByBackendId("ProfilingOptIn"),
      ThirdPartiesDataTranAuthOptIn: getUserSubscriptionValueByBackendId("ThirdPartiesDataTranAuthOptIn"),
    };
  };

  return {
    availableSubscriptions,
    getUserSubscriptionByBackendId,
    getUserSubscriptionValueByBackendId,
    hasLoyaltyProgram: loyaltyUtils.getLoyaltyProgram(availableSubscriptions),
    isLoadingUserSubscriptions,
    pushSubscriptionsByFlags,
    removeSubscriptionsByFlags,
    userSubscriptionsToFlags,
    userActiveSubscriptions: userSubscriptions?.filter(_isUserSubscriptionActive) || [],
    userSubscriptions,
  };
};

export default useSubscriptions;
